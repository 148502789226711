<template>
    <div>
        <TableList
            :form_data_seo.sync="form_data_seo"
            :columns="columns"
            :get_table_list="this.get_table_list"
            :slot_table_list="['address']"
            ref="list"
            :submit_preprocessing="submit_preprocessing"
        >

			<template slot="right_btn">
                <export-table v-if="this.$store.state.page_auth.export"
                    url="/manage/crm.export/onLineLog"
                ></export-table>
            </template>

			<template slot="address" slot-scope="data">
                {{data.record.province + data.record.city + data.record.district + data.record.address}}
            </template>
        </TableList>

    </div>
</template>

<script>

import TableList from "@/components/TableList";
import { onLineLog } from "@/api/business";
                                                                                                                                                                                                                             
const columns = [
    {
        title: "姓名",
        dataIndex: "username",
    },
    {
        title: "部门",
        dataIndex: "department",
    },
    {
        title: "岗位",
        dataIndex: "role",
    },
    {
        title: "账号",
        dataIndex: "phone",
    },
    {
        title: "在线时间",
        dataIndex: "start_time",
    },
    {
        title: "持续时间",
        dataIndex: "time",
    },
    {
        title: "离线时间",
        dataIndex: "end_time",
    },
	{
        title: "当前位置",
        dataIndex: "address",
    },
	{
        title: "城乡类型",
        dataIndex: "town_type",
    },
	{
        title: "乡镇名称",
        dataIndex: "town",
    },
	{
        title: "手机品牌",
        dataIndex: "brand",
    },
	{
        title: "设备名称",
        dataIndex: "device_name",
    },
	{
        title: "系统版本",
        dataIndex: "system_version",
    },
	{
        title: "动销版本",
        dataIndex: "app_version",
    },
];

export default {
    name: "Index",
    components: {
		TableList,
    },
    data() {
        return {
            get_table_list: onLineLog,
            submit_preprocessing: {
                array_to_string: ['department','role_id']
            },
            order_details: {
                goods: [],
            },
            columns,
            form_data_seo: {
                list: [
					{
                        type: "tree-select",
                        name: "department",
                        title: "部门",
                        options: {},
                        treeData: [],
                        multiple: true
                    },
					{
                        type: "tree-select",
                        name: "role_id",
                        title: "岗位职务",
                        options: {},
                        treeData: [],
                        multiple: true
                    },
					// {
                    //     type: "select",
                    //     name: "role_id",
                    //     title: "岗位职务",
                    //     mode: "default",
                    //     options: {},
                    //     list: []
                    // },
                    {
                        type: "text",
                        name: "username",
                        title: "姓名",
                        placeholder: '姓名/账号/工号/手机号',
                        mode: "default",
                        options: {},
                        list: []
					},
					{
						type: "select",
						name: "user_status",
						title: "在职状态",
						mode: "default",
						options: {},
						list: this.$config.user_status_list
					},
					{
                        type: "range_date",
                        name: "range_date",
                        title: "登录时间",
                        options: {
                        	initialValue: [this.$moment(new Date(), 'YYYY/MM/DD'), this.$moment(new Date(), 'YYYY/MM/DD')],
                        },
						
                        start: {
                            name: 'start_time'
                        },
                        end: {
                            name: 'end_time'
                        },
                    },
                ],
                ...this.$config.form_data_seo
            },
            form_data: {}
        };
    },
    async created() {
        this.$method.get_department().then(res => {
			this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list,'department','treeData',res)
		});
		// this.$method.get_role().then(res => {
        //     this.form_data_seo.list.forEach(item => {
        //         if (item.name == "role_id") {
        //             item.list = res;
        //         }
        //     });
        // });
        
        this.$method.get_role_tree().then(res => {
			this.form_data_seo.list.forEach(item => {
				if (item.name == "role_id") {
					item.treeData = res;
				}
			});
		});
    },
    methods: {

    }
};
</script>

<style lang="less">

</style>